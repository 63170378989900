import React from "react";
import styled from "styled-components";
import moni from "../images/moni.svg";
import advantage from "../images/advantage_club.svg";
import topship from "../images/topship.png";
import nairametrics from "../images/nairametrics.png";

const PortfolioStyles = styled.article`
    @media (min-width: 320px) and (max-width: 480px) {
        display: flex;
        flex-direction: column;
        /* width: 80%; */
        margin: 30px auto 0 auto;
    }

    @media (min-width: 481px) and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        /* height: 200px; */
        margin-top: 400px;
    }

    @media (min-width: 769px) and (max-width: 1024px) {
        width: 96%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding: 40px;
        /* height: auto; */
    }

    @media (min-width: 1024px) {
        max-width: 1224px;
        margin: 0 auto;
        padding-top: 60px;
        /* height: 310px; */
        display: grid;
        grid-template-rows: 150px;
        justify-content: space-between;
        grid-template-columns: 35% 65%;
    }
    .left {
        display: flex;
        align-self: center;
    }
    .folio-text {
        font-size: 26px;
        line-height: 100px;
        font-style: normal;
        letter-spacing: -4%;
        font-weight: 600;
        font-family: "Graphik";
        align-self: center;
    }

    @media (min-width: 320px) and (max-width: 480px) {
        .boxes {
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(2, 150px);
            grid-template-rows: repeat(2, 150px);
            grid-gap: 20px;
        }
    }
    @media (min-width: 481px) and (max-width: 768px) {
        .boxes {
            width: 84%;
            margin: 0 auto;
            grid-template-columns: repeat(2, 150px);
            grid-template-rows: repeat(2, 150px);
            grid-row-gap: 50px;
            grid-column-gap: 50px;
            justify-content: space-between;
        }
    }
    @media (min-width: 769px) and (max-width: 1024px) {
        .boxes {
            grid-template-columns: repeat(4, 120px);
            grid-template-rows: 120px;
            grid-column-gap: 20px;
        }
    }
    @media (min-width: 1024px) {
        .boxes {
            display: grid;
            grid-template-columns: repeat(4, 150px);
            justify-content: space-between;
        }
    }

    .rectangle {
        width: 150px;
        height: 150px;
        position: relative;
    }
    .rectangle img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
`;

function Portfolio() {
    return (
        <PortfolioStyles>
            <div className="left">
                <p className="folio-text">Our portfolio</p>
            </div>
            <div className="boxes">
                <div className="rectangle">
                    <img src={moni} width="150" alt="Moni Africa" />
                </div>
                <div className="rectangle">
                    <img src={topship} width="150" alt="Topship" />
                </div>
                <div className="rectangle">
                    <img src={nairametrics} width="150" alt="Naira Metrics" />
                </div>
                <div className="rectangle">
                    <img src={advantage} width="150" alt="Advantage Club" />
                </div>
            </div>
        </PortfolioStyles>
    );
}
export default Portfolio;
